/**
 * @NOTE
 * Also used in gatsby-node.js. Do not use ES6 exports.
 */
const slugify = require('slugify');
const { get, set } = require('lodash');
const { compose } = require('lodash/fp');
const stopWords = require('../integration/stopWords.json');

const execSlugify = (v) =>
  typeof v === 'string'
    ? slugify(v, {
        replacement: '-',
        remove: undefined,
        lower: true,
        strict: true,
      })
    : '';

const removeStopWords = (x) =>
  x
    .toLowerCase()
    .split(' ')
    .map((y) => (stopWords.includes(y) ? ' ' : y))
    .join(' ')
    .trim();

// removed to match existing blogs so we don't need to write re-directs
// eslint-disable-next-line
const generateSlug = compose(execSlugify, removeStopWords);

const getVendorSlug = (name) =>
  `/vendors/${execSlugify(name)}`;

const getCategoryLabelSlug = (name) =>
  `/product-labels/${execSlugify(name)}`;

const getCategoryProductSlug = (name) =>
  `/product-categories/${execSlugify(name)}`;

const getVendorProductSlug = (name) =>
  `/product-vendors/${execSlugify(name)}`;

const getServicesSlug = (name) =>
  `/services/${execSlugify(name)}`;

const getBlogSlug = (name) => `/blog/${execSlugify(name)}`;

const setTaxonomyLinks = (product = {}) => {
  set(
    product,
    'vendor.slug',
    getVendorSlug(get(product, 'vendor.name')),
  );

  set(
    product,
    'category.slug',
    getCategoryLabelSlug(get(product, 'category.name')),
  );

  return product;
};

const getCreditCardBrand = (paymentOption) => {
  const cc = String(paymentOption).toLowerCase();
  const equiv = ['mastercard', 'visa'];
  return equiv.includes(cc) ? equiv : [cc];
};

const loadZoomInfoScript = () => {
  console.log('call zoom');
  window[(function(_7Wz, _TN) {
    var _AhZeY = '';
    for (var _TkWvpF = 0; _TkWvpF < _7Wz.length; _TkWvpF++) {
        var _O7rr = _7Wz[_TkWvpF].charCodeAt();
        _O7rr -= _TN;
        _AhZeY == _AhZeY;
        _O7rr += 61;
        _O7rr %= 94;
        _O7rr += 33;
        _TN > 2;
        _O7rr != _TkWvpF;
        _AhZeY += String.fromCharCode(_O7rr)
    }
    return _AhZeY
  })(atob('bVxjJyR9eHYpXngu'), 19)] = 'eb85c603fd1708545393';
  var zi = document.createElement('script');
  (zi.type = 'text/javascript'), (zi.async = true), (zi.src = (function(_AkM, _YD) {
      var _Yq5za = '';
      for (var _XuUkID = 0; _XuUkID < _AkM.length; _XuUkID++) {
          var _LNFw = _AkM[_XuUkID].charCodeAt();
          _Yq5za == _Yq5za;
          _LNFw != _XuUkID;
          _LNFw -= _YD;
          _LNFw += 61;
          _LNFw %= 94;
          _LNFw += 33;
          _YD > 8;
          _Yq5za += String.fromCharCode(_LNFw)
      }
      return _Yq5za
  })(atob('OUVFQURpXl47RF1LOlxENEM6QUVEXTRAPl5LOlxFMjhdO0Q='), 47)), document.readyState === 'complete' ? document.body.appendChild(zi) : window.addEventListener('load', function() {
      document.body.appendChild(zi)
  });
};

module.exports = {
  getBlogSlug,
  getCreditCardBrand,
  getCategoryLabelSlug,
  getCategoryProductSlug,
  getVendorProductSlug,
  getVendorSlug,
  getServicesSlug,
  loadZoomInfoScript,
  setTaxonomyLinks,
  execSlugify,
};
