import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardMedia from '@material-ui/core/CardMedia';
import useVendorLogo from '../useVendorLogo';
import { getVendorSlug } from '../utils';

const LARGE = 'lg';
const MEDIUM = 'md';

const VendorCard = ({ name, code, size }) => {
  const isLarge = size === LARGE;
  const gridItemProps = isLarge
    ? {
        xl: 2,
        lg: 3,
        md: 4,
        xs: 6,
      }
    : {
        xl: 2,
        lg: 2,
        md: 2,
        xs: 4,
      };

  return (
    <Grid item {...gridItemProps}>
      <Paper variant="outlined">
        <Link
          aria-label={`${name} vendor profile`}
          to={getVendorSlug(name)}
          style={{
            display: 'block',
            textDecoration: 'none',
          }}
        >
          <CardMedia
            image={useVendorLogo(code)}
            style={{
              height: isLarge ? 195 : 125,
              backgroundSize: '60%',
              margin: '0 auto',
            }}
          />
        </Link>
      </Paper>
    </Grid>
  );
};

VendorCard.defaultProps = {
  size: LARGE,
};

VendorCard.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf([MEDIUM, LARGE]),
};

export default VendorCard;
