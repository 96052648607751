import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ProductsHeader from '../components/ProductsHeader';
import SEOHelmet from '../components/SEOHelmet';
import VendorCard from '../components/VendorCard';

const Vendors = ({ data: { vendors, contentfulPage } }) => (
  <ProductsHeader
    title={get(contentfulPage, 'banner.title')}
    description={get(contentfulPage, 'banner.leadIn.raw')}
  >
    <Box pb={4}>
      <Container maxWidth="xl">
        <SEOHelmet
          title={get(contentfulPage, 'seo.title')}
          description={get(
            contentfulPage,
            'seo.metaDescription.metaDescription',
          )}
        />
        <Container maxWidth="xl">
          <Grid container spacing={1} justify="center">
            {vendors.nodes.map((node) => (
              <VendorCard key={node.id} {...node} />
            ))}
          </Grid>
        </Container>
      </Container>
    </Box>
  </ProductsHeader>
);

Vendors.defaultProps = {
  data: {
    contentfulPage: {},
    vendors: {
      nodes: [],
    },
  },
};

Vendors.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({
      // eslint-disable-next-line
      banner: PropTypes.object,
      // eslint-disable-next-line
      seo: PropTypes.object,
    }).isRequired,
    vendors: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  }),
};

export default Vendors;

export const query = graphql`
  {
    vendors: allContentfulVendor(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        code
      }
    }

    contentfulPage(
      contentful_id: { eq: "7ClE3JX9y9r3lM4fv5Xj3J" }
    ) {
      banner {
        title
        leadIn {
          raw
        }
      }
      seo {
        title
        metaDescription {
          metaDescription
        }
      }
    }
  }
`;
